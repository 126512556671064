import { Paper, Typography, Box, useTheme, Button } from '@mui/material';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { useTranslation } from 'react-i18next';
import CenteredContainer from '../../../../components/layout/CenteredContainer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../store/actions/userActions';
import { MARGIN_LARGE } from '../../../../shared/constants/layout-constants';

export const SignupThankYouPage = () => {
  const { t } = useTranslation();

  // Fetch patient data
  const { patient_plan } = getMyPatientData();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [patientPlanId, setPatientPlanId] = useState(patient_plan?.id || '');

  useEffect(() => {
    if (patient_plan?.id) {
      setPatientPlanId(patient_plan.id);
    }
    dispatch(logout({}, () => {}));
  }, [patient_plan?.id, dispatch]);

  return (

    <CenteredContainer>
      <Box
        style={{ backgroundColor: 'white' }}
        sx={{
          width: '95vw',
          [theme.breakpoints.up('md')]: {
            width: 1200,
          },
        }}
      >
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            {t('Thank You')}
          </Typography>

          <Box mb={4}>
            <Typography variant="h6">{t('Your application has been successfully submitted.')}</Typography>
            <Typography variant="body1">{t('Your Patient Plan ID:')}</Typography>
            <Typography variant="h5" color="primary" fontWeight="bold">
              {patientPlanId || t('N/A')}
            </Typography>
          </Box>

          <Button variant="outlined" style={{marginTop: MARGIN_LARGE}} onClick={() => {
          window.location.href = process.env.REACT_APP_LANDING_PAGE_URL || "https://admin.comfortdental.com/"
        }}>Login To Portal</Button>

        </Paper>


      </Box>
    </CenteredContainer>


  );
};