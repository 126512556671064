import { ElementRef, forwardRef } from 'react';
import { Select, SelectItem, SelectProps } from './Select';
import { GENDER } from '../../shared/constants/user.constants';

export type GenderProps = Omit<SelectProps, 'options'>;

export const Gender = forwardRef<ElementRef<typeof Select>, GenderProps>((props, ref) => {
  const options: SelectItem[] = [
    { value: GENDER.MALE.toString(), label: 'Male' },
    { value: GENDER.FEMALE.toString(), label: 'Female' },
    { value: GENDER.OTHER.toString(), label: 'Other' },
  ];

  return <Select ref={ref} {...props} options={options} />;
});
