import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./genericAction";

// export const createPatientContact = genericAction(ACTIONS.CREATE_PATIENT_CONTACT)
export const signupCreateUser = genericAction(ACTIONS.SIGNUP_CREATE_USER);
export const signupUpsertYourInfo = genericAction(ACTIONS.SIGNUP_UPSERT_YOUR_INFO);
export const signIn = genericAction(ACTIONS.SIGN_IN);
export const getAllLocations = genericAction(ACTIONS.GET_ALL_LOCATIONS);
export const setLocation = genericAction(ACTIONS.SIGNUP_SET_LOCATION);
export const signupUpdateFamilyMembers = genericAction(ACTIONS.SIGNUP_UPDATE_FAMILY_MEMBERS);
export const signupUpsertPaymentProfile = genericAction(ACTIONS.SIGNUP_UPSERT_PAYMENT_PROFILE);
export const signupSignNow = genericAction(ACTIONS.UPSERT_SIGNNOW_DOCUMENT);
export const signupSubmitApplication = genericAction(ACTIONS.SIGNUP_SUBMIT_APPLICATION);
