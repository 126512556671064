
import { put } from "redux-saga/effects"; //call

const clientOptionSaga = {
    * setClientOptions(action: any): Generator {
        try {
            yield put({
                type: "SET_CLIENT_OPTIONS_SUCCESS",
                normalized: {
                    entities: {
                        clientOptions: {...action.payload, updated: new Date()}
                    }
                }
            });

            if (action.onSuccess) {
                action.onSuccess();
            }
        } catch (e) {
            if (action.onFail) {
                action.onFail();
            }
        }
    },

}

export default clientOptionSaga;