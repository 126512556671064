import { ElementRef, forwardRef, useEffect, useMemo } from 'react';
import { getStatesArray } from '../../store/selectors/main';
import { useSelector } from '../../store';
import { Select, SelectItem, SelectProps } from './Select';
import { useDispatch } from 'react-redux';
import { getAllLocations } from '../../store/actions/signupActions';

export type StatesProps = Omit<SelectProps, 'options'>;

export const States = forwardRef<ElementRef<typeof Select>, StatesProps>((props, ref) => {
  const dispatch = useDispatch();
  const states = useSelector(getStatesArray);

  const options: SelectItem[] = useMemo(() => states?.map((i) => ({ value: i.id?.toString(), label: i.name })) ?? [], [states]);

  useEffect(() => {
    dispatch(getAllLocations({}));
  }, []);

  return <Select ref={ref} {...props} options={options} />;
});
