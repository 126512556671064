import { ElementRef, forwardRef } from 'react';
import { Select, SelectItem, SelectProps } from './Select';

export type YearProps = Omit<SelectProps, 'options'>;

export const Year = forwardRef<ElementRef<typeof Select>, YearProps>((props, ref) => {
  const currentYear = new Date().getFullYear();
  const options: SelectItem[] = Array.from(new Array(13), (_, index) => currentYear + index).map((i) => ({ label: i.toString(), value: i.toString() }));
  return <Select ref={ref} {...props} options={options} />;
});
