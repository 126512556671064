import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./genericAction";

export const getUser = genericAction(ACTIONS.GET_USER);
export const getUsers = genericAction(ACTIONS.GET_USERS);
export const getRoles = genericAction(ACTIONS.GET_ROLES);
export const updateMyUser = genericAction(ACTIONS.UPDATE_MY_USER);
export const userActivation = genericAction(ACTIONS.USER_ACTIVATION);
export const downloadUsersReport = genericAction(ACTIONS.DOWNLOAD_USERS_REPORT);
export const addRemoveRole = genericAction(ACTIONS.ADD_REMOVE_ROLE);

export const getMyUserData = genericAction(ACTIONS.GET_MY_USER_DATA);
export const logout = genericAction(ACTIONS.LOGOUT);
