import { createSelector } from 'reselect';
import { IRootState } from '../../interfaces/store';

// Input Selectors
const getAddressState = (state: IRootState) => state.main.addresses;
const getUserState = (state: IRootState) => state.main.asp_net_users;
const getContactState = (state: IRootState) => state.main.contacts;
const getLocationState = (state: IRootState) => state.main.locations;
const getPatientState = (state: IRootState) => state.main.patients;
const getPaymentDataState = (state: IRootState) => state.main.payment_datas;
const getPatientPlans = (state: IRootState) => state.main.patient_plans;
const getStateState = (state: IRootState) => state.main.states;
const getDependents = (state: IRootState) => state.main.dependents;
const getDependentPatientPlans = (state: IRootState) => state.main.dependent_patient_plans;
const getRelationships = (state: IRootState) => state.main.relationships;
const getProducts = (state: IRootState) => state.main.products;

// Output Selectors
export const getAddressesArray = createSelector([getAddressState], (addresses) => Object.values(addresses));
export const getUsersArray = createSelector([getUserState], (users) => Object.values(users));
export const getContactsArray = createSelector([getContactState], (contacts) => Object.values(contacts));
export const getLocationsArray = createSelector([getLocationState], (locations) => Object.values(locations));
export const getPatientsArray = createSelector([getPatientState], (patients) => Object.values(patients));
export const getDependentsArray = createSelector([getDependents], (dependents) => Object.values(dependents));
export const getDependentPatientPlansArray = createSelector([getDependentPatientPlans], (dependentPatientPlans) => Object.values(dependentPatientPlans));
export const getRelationshipsArray = createSelector([getRelationships], (relationships) => Object.values(relationships));
export const getPaymentDatasArray = createSelector([getPaymentDataState], (paymentDatas) => Object.values(paymentDatas));
export const getStatesArray = createSelector([getStateState], (states) => Object.values(states));
export const getPatientPlansArray = createSelector([getPatientPlans], (patientPlans) => Object.values(patientPlans));

export const getProductsArray = createSelector([getProducts], (products) => Object.values(products));

// If the gold product codes are static, define them as a constant
const goldProductCodes = [
"2022_06_01_g_0d_m",
"2022_06_01_g_0d_a",
"2022_06_01_g_1d_m",
"2022_06_01_g_1d_a",
"2022_06_01_g_3d_m",
"2022_06_01_g_3d_a",
"2022_06_01_g_4d_m",
"2022_06_01_g_4d_a"
]; // Example codes, replace with actual codes


// Selector to get gold products array
export const getCurrentGoldProductsArray = createSelector(
    [getProductsArray],
    (productsArray) => productsArray.filter(product => goldProductCodes.includes(product.productCode!))
);
// Now you can use these selectors with useSelector in your components to get arrays of each entity type:
// const locations = useSelector(getLocationsArray);
// const addresses = useSelector(getAddressesArray);
// const users = useSelector(getUsersArray);
// ... and so on