export enum APP_BREAKPOINT {
    EXTRA_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
  }
  
  export enum COLORS {
    WHITE_BACKGROUND = 'white',
    PRIMARY = '#007f7e',
    SECONDARY = '#94368f',
    WHITE_PAPER = '#EDEDED',
    GREY_DARK = '#555555',
    MODAL_BACKGROUND = "rgba(248, 250, 251, 0.6)",
  }

  export enum THEME_NAME {
    LIGHT = 'light',
    DARK = 'dark',
  }

  export interface ITheme {
    colors: IColorsTheme,
    classes: {
      main: string,
    }
  }

  export interface IColorsTheme {
    background: string;
    font: string;
    accent_primary: string;
    icon_primary: string;
    sidebar_background: string;
  }

  export interface IClassesTheme {
    main: string
  }

export const getThemeColors = (theme: THEME_NAME): IColorsTheme => {
  let colorsDefs = {
    background: 'white',
    font: 'black',
    accent_primary: '#00a49e',
    icon_primary: '#707070',
    sidebar_background: 'white',

  }
  switch(theme) {
    case THEME_NAME.LIGHT: //default
      break;
    case THEME_NAME.DARK:
      colorsDefs = {
        background: 'black',
        font: 'white',
        accent_primary: '#00a49e',
        icon_primary: '#00a49e',
        sidebar_background: '#0d1117'
      }
      break;
  }
  return colorsDefs
}

export const getThemeClasses = (theme: THEME_NAME): IClassesTheme => {
  let classesDef = {
    main: 'theme-light'
  }
  switch(theme) {
    case THEME_NAME.LIGHT: //default
      break;
    case THEME_NAME.DARK:
      classesDef = {
        main: 'theme-dark'
      }
      break;
  }
  return classesDef
}

  
  export const MARGIN_SMALL = 8;
  export const MARGIN_MEDIUM = 16;
  export const MARGIN_LARGE = 32;
  export const MARGIN_XLARGE = 64;
  
  export const errorStyle = {
    color: 'red'
  }
  
  export const TABLE_DEFAULT_PAGE_SIZE = 15;
  export const TABLE_DEFAULT_PAGE_SIZES = [5, 15, 30, 50, 100]