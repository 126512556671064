export enum DocumentStatus {
    NoInvite = 0,
    Pending = 1,
    Completed = 2,
    Unknown = 99
}

export function interpretDocumentStatus(statusValue: number): string {
    switch (statusValue) {
        case DocumentStatus.NoInvite:
            return "NoInvite";
        case DocumentStatus.Pending:
            return "Pending";
        case DocumentStatus.Completed:
            return "Completed";
        default:
            return "Unknown Status";
    }
}