import React, { CSSProperties, forwardRef } from "react";
import { Grid } from "@mui/material";

// Defina o componente usando forwardRef para aceitar uma ref
const CenteredContainer = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{}>
>(({ children }, ref) => {
  return (
    <Grid
      ref={ref}
      container
      justifyContent="center"
      sx={{ mt: 4 }}
    >
      <Grid item>{children}</Grid>
    </Grid>
  );
});

// Defina o displayName para facilitar a depuração
CenteredContainer.displayName = "CenteredContainer";

export default CenteredContainer;
