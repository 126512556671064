import { Form, Formik, FormikProps } from 'formik';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { signupSignNow } from '../../../../store/actions/signupActions';
import { SignupRegisterSubmit } from './SignupRegisterSubmit';
import { DocumentStatus } from '../../../../shared/interfaces/sign-now.interfaces';

const validationSchema = Yup.object().shape({
  terms: Yup.boolean().oneOf([true], 'You must accept the terms of service to continue.'),
});

export type SignupRegisterReviewFormType = Yup.InferType<typeof validationSchema>;

export type SignupRegisterReviewProps = { onSuccess: () => void };

export const SignupRegisterReview = forwardRef<FormikProps<SignupRegisterReviewFormType>, SignupRegisterReviewProps>(({ onSuccess }, ref) => {
  const dispatch = useDispatch();


  const [signNowLink, setSignNowLink] = useState<string | null>(null);
  const [showFinalSubmit, setShowFinalSubmit] = useState<boolean>(false);
  const hasDispatchedSignNow = useRef(false) as any;

  useEffect(() => {
    // setShowFinalSubmit(true);
  }, [])

  useEffect(() => {
    if (!hasDispatchedSignNow.current) {
      dispatch(signupSignNow({}, (data) => {
        if (data.patient_plan?.signNowData?.status === DocumentStatus.Completed || data.patient_plan?.signNowData?.status === DocumentStatus.Unknown) {
          setShowFinalSubmit(true);
        }
        else if (data.patient_plan?.signNowData?.EmbeddedInvite) {
          setSignNowLink(data.patient_plan.signNowData.EmbeddedInvite);
          console.log("link", data.patient_plan.signNowData.EmbeddedInvite);
        }
      }));
      hasDispatchedSignNow.current = true;
    }
  }, [dispatch]);

  // Event listener to capture messages from the iframe
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Log the message from the iframe regardless of the origin
      console.log("Received message from iframe:", event.data);
  
      // Check if the event data has a 'path' property and if it contains 'thank-you'
      if (event?.data?.path?.includes('thank-you')) {
        setShowFinalSubmit(true);
      }
    };
  
    window.addEventListener("message", handleMessage);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (showFinalSubmit){
    return (<>
    <SignupRegisterSubmit />
    </>)
  }

  if (!signNowLink) return null;

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={{
          terms: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(_, actions) => {
          onSuccess();
          actions.setSubmitting(false);
        }}
      >
        {({ handleChange, handleBlur, values, touched, errors }) => (
          <Form>
            <iframe src={signNowLink} width="100%" height={Math.max(800, window.innerHeight - 300)} />
          </Form>
        )}
      </Formik>
    </>
  );
});
