import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './ar.json';
import en from './en.json';
import es from './es.json';
import ja from './ja.json';
import pt from './pt.json';
import it from './it.json';
import vi from './vi.json'; // Import Vietnamese
import de from './de.json'; // Import German

export const resources = {
  ar: ar,
  en: en,
  es: es,
  ja: ja,
  pt: pt,
  it: it,
  vi: vi, // Add Vietnamese
  de: de, // Add German
};

// @ts-ignore
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en', // default language to use.
  });

export default { i18n };