import React, { useEffect, useRef, useState } from "react";
import { Tab, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CenteredContainer from "../../../components/layout/CenteredContainer";
import { SignupLogin } from "./SignupLogin";
import { SignupRegister } from "./SignupRegister";
import { useNavigate } from "react-router-dom";
import { getMyPatientData } from "../../../store/hooks/getMyPatientData";
import { MARGIN_LARGE, MARGIN_MEDIUM, MARGIN_SMALL, MARGIN_XLARGE } from "../../../shared/constants/layout-constants";
import { useTranslation } from "react-i18next";

type Tabs = "register" | "login";

export const Signup = () => {
  const [value, setValue] = useState<Tabs>("register");
  const [height, setHeight] = useState<number>(0);
  const container = useRef<HTMLDivElement>(null);

  const {t} = useTranslation();
  const handleChange = (_: React.SyntheticEvent, newValue: Tabs) => {
    setValue(newValue);
    if (container.current && container.current.clientHeight > height) {
      setHeight(container.current.clientHeight);
    }
  };

  const myPatientData = getMyPatientData();
  const navigate = useNavigate();


  useEffect(() => {
    if (myPatientData.my_user?.email && !myPatientData.signup_complete) {
      navigate('/signup/register')
    };
  }, [myPatientData.signup_complete, myPatientData.my_user?.email]);

  return (
    <CenteredContainer>
      <Container
        ref={container}
        maxWidth="lg"
        style={{
          height: height === 0 ? "auto" : height,
        }}
      >
        <div style={{color: '#810281', width: '100%', textAlign: 'center'}}>
        <h2>{t('Join the comfort dental gold plan')}</h2>

          </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: MARGIN_XLARGE }}>
          <div style={{ flex: 1 }}>
            <p style={{marginTop: 0, paddingTop: 0}}>{t('signupParagraph1')}</p>
            <p>{t('signupParagraph2')}</p>
            <p>{t('signupParagraph3')}</p>
          </div>
          <div style={{ flex: 1, marginLeft: MARGIN_XLARGE, backgroundColor: 'white', borderRadius: MARGIN_SMALL }}>
            <CenteredContainer>
              <TabContext value={value}>
                <TabList onChange={handleChange} centered>
                  <Tab label={t('register')} value="register" />
                  <Tab label={t('login')} value="login" />
                </TabList>
                <TabPanel value="register">
                  <SignupRegister />
                </TabPanel>
                <TabPanel value="login">
                  <SignupLogin />
                </TabPanel>
              </TabContext>
            </CenteredContainer>
          </div>
        </div>


      </Container>
    </CenteredContainer>
  );
};

export default Signup;
