import { omit } from "lodash";

export function autoReduce (action: any, state: any) {
    if (action.normalized) {
        // Initialize a shallow copy of state to preserve immutability
        let newState = {...state};
    
        Object.keys(action.normalized.entities).forEach((key) => {
            // Directly modify only the necessary part of the newState
            newState[key] = Object.assign({}, newState[key], action.normalized.entities[key]);
        });
    
        // Update the state reference with the newly formed newState
        state = newState;
    }
    if (action.removeKeys) {
        for (let i = 0; i < action.removeKeys.length; i += 1) {
            state[action.removeKeys[i].entities] = omit(
                state[action.removeKeys[i].entities],
                action.removeKeys[i].ids
            );
        }
    }
    if (action.overrides){
        action.overrides.forEach((x: any) => {
            state[x.key] = x.value;
        });
    }
    if (action.mergeKeys){
        action.mergeKeys.forEach((x: any) => {
            state[x.key] = {
                ...state[x.key],
                ...x.value
            };
        });
    }
	if (action.forceStateRefresh){
		return {
			...state,
			force_state_refresh: state.force_state_refresh + 1
		}
	}
    return state;
}
