import { ElementRef, forwardRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export type DateFieldProps = { name: string; label: string };

export const DateField = forwardRef<ElementRef<typeof DatePicker>, DateFieldProps>(({ name, label, ...props }, ref) => {
  const [field] = useField(name);
  const { setFieldValue, touched, errors } = useFormikContext() as any;

  // Ensure that the value passed to DatePicker is a moment object
  const currentValue = field.value ? moment(field.value) : null;

  // Check for errors and touched state for this field
  const isError = Boolean(errors[name] && touched[name]);
  const errorMessage = isError ? errors[name] : '';

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...props}
        ref={ref}
        label={label}
        value={currentValue} // Pass moment object or null
        onChange={(val) => {
          // Convert to standard Date object before passing to Formik
          setFieldValue(name, val ? val.toDate() : null);
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            error: isError,
            helperText: errorMessage,
          },
        }}
      />
    </LocalizationProvider>
  );
});

DateField.displayName = 'DateField';