import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { signupUpsertYourInfo } from '../../../../store/actions/signupActions';
import { Button, Grid, TextField } from '@mui/material';
import { Select } from '../../../../components/form/Select';
import { COMMUNICATION_METHOD } from '../../../../shared/constants/user.constants';
import { States } from '../../../../components/form/States';
import { Gender } from '../../../../components/form/Gender';
import { DateField } from '../../../../components/form/DateField';
import { setClientOptions } from '../../../../store/actions/clientOptionsActions';
import { validateAndSubmitForm } from '../../../../shared/utils/gen-utils';
import { useTranslation } from 'react-i18next';

const phoneRegExp = /^(\+?\d{1,3}[-.\s]?|\(\d{1,3}\)\s?)?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}( ext \d{1,5})?$/;
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  middleInitial: Yup.string().optional(),
  lastName: Yup.string().required('Last Name is required'),
  streetAddress1: Yup.string().required('Street Address is required'),
  streetAddress2: Yup.string().optional(),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  code: Yup.string().required('Zip is required'),
  birthDate: Yup.date()
    .required('Birth date is required')
    .test('age', 'You must be at least 18 years old', (value) => {
      return new Date(value) <= new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    }),
  gender: Yup.string().required('Gender is required'),
  communication: Yup.string().required('Preferred communication method is required'),
});
export type SignupRegisterYourInfoType = Partial<Yup.InferType<typeof validationSchema>>;

export type SignupRegisterYourInfoProps = { onSuccess: () => void };

export const SignupRegisterYourInfo = forwardRef<FormikProps<SignupRegisterYourInfoType>, SignupRegisterYourInfoProps>(({ onSuccess }, ref) => {
  const myPatientData = getMyPatientData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Formik
      innerRef={ref}
      initialValues={{
        firstName: myPatientData?.contact?.firstName || '',
        lastName: myPatientData?.contact?.lastName || '',
        streetAddress1: myPatientData?.address?.street || '',
        streetAddress2: myPatientData?.address?.streetLine2 || '',
        middleInitial: myPatientData?.contact?.middleInitial || '',
        city: myPatientData?.address?.city || '',
        state: myPatientData?.address?.stateId?.toString() || '', //THIS NEEDS TO BE CONVERTED TO A STATE DROPDOWN
        code: myPatientData?.address?.code || '',
        phoneNumber: myPatientData?.contact?.phoneNumber || '',
        birthDate: myPatientData?.contact?.birthDate,
        gender: myPatientData?.contact?.gender?.toString() ?? '',
        communication: myPatientData?.patient?.preferredCommunication?.toString() ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        return new Promise((resolve) => {
          actions.setSubmitting(false);
          const Contact = {
            FirstName: values.firstName,
            LastName: values.lastName,
            MiddleInitial: values.middleInitial,
            PhoneNumber: values.phoneNumber,
            BirthDate: values.birthDate,
            Gender: values.gender,
          };
          const Address = {
            Street: values.streetAddress1,
            StreetLine2: values.streetAddress2,
            City: values.city,
            StateId: values.state,
            Code: values.code?.toString(),
          };
          dispatch(
            signupUpsertYourInfo(
              {
                Contact,
                Address,
                preferredCommunication: values.communication,
              },
              (data) => {
                console.log('upsert info success');
                if (onSuccess){
                  onSuccess();
                }
                return resolve({
                  ...data,
                  status: 'success'
                })
              },
              () => {},
              '',
              true,
              true,
            ),
          );
        });

      }}
    >
      {({ touched, errors, submitForm, validateForm, isValid, setTouched }) : any => {
        return (
          <Form style={{ marginTop: 32 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label={t('firstName')}
                  fullWidth
                  variant="outlined"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={<ErrorMessage name="firstName" />}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Field
                  as={TextField}
                  name="lastName"
                  label={t('lastName')}
                  fullWidth
                  variant="outlined"
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={<ErrorMessage name="lastName" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Field as={TextField} name="middleInitial" label="M.I." fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="streetAddress1"
                  label={t('addressLine1')}
                  fullWidth
                  variant="outlined"
                  error={touched.streetAddress1 && Boolean(errors.streetAddress1)}
                  helperText={<ErrorMessage name="streetAddress1" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Field as={TextField} name="streetAddress2" label={t('addressLine2')} fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field
                  as={TextField}
                  name="city"
                  label={t('city')}
                  fullWidth
                  variant="outlined"
                  error={touched.city && Boolean(errors.city)}
                  helperText={<ErrorMessage name="city" />}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field as={States} name={'state'} label={'State'} error={touched.state && Boolean(errors.state)} helperText={<ErrorMessage name="state" />} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field as={TextField} name="code" label="Zip" fullWidth variant="outlined" error={touched.code && Boolean(errors.code)} helperText={<ErrorMessage name="code" />} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="phoneNumber"
                  type="tel"
                  label={t('phoneNumber')}
                  fullWidth
                  variant="outlined"
                  placeholder="123-123-1234 ext 123"
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={<ErrorMessage name="phoneNumber" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={DateField}
                  name="birthDate"
                  label={t('birthDate')}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={touched.birthDate && Boolean(errors.birthDate)}
                  helperText={<ErrorMessage name="birthDate" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field as={Gender} name={'gender'} label={'Gender'} error={touched.gender && Boolean(errors.gender)} helperText={<ErrorMessage name="gender" />} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={Select}
                  options={[
                    { value: COMMUNICATION_METHOD.PHONE, label: 'Phone' },
                    { value: COMMUNICATION_METHOD.EMAIL, label: 'Email' },
                    { value: COMMUNICATION_METHOD.MAIL, label: 'Mail' },
                  ]}
                  name={'communication'}
                  label={t('preferredCommunication')}
                  error={touched.communication && Boolean(errors.communication)}
                  helperText={<ErrorMessage name="communication" />}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  onClick={() => {

                    validateAndSubmitForm(
                      validateForm,
                      setTouched,
                      submitForm,
                      isValid,
                      () => {
                       //console.log("Form submitted successfully from button");
                        dispatch(setClientOptions({ signUpStep: "location" }));
                      },
                      (error) => {
                        console.log("Form submission failed or has errors", error);
                        // Handle additional failure logic
                      }
                    );
                    // validateForm().then((errors: any) => {
                    //   if (Object.keys(errors).length > 0) {
                    //     setTouched(
                    //       Object.keys(errors).reduce((acc:any, key) => {
                    //         acc[key] = true;
                    //         return acc;
                    //       }, {})
                    //     );
                    //     console.log("Form has errors");
                    //     return;
                    //   }
                    //   submitForm()
                    //   .then(() => {
                    //     if (isValid){
                    //      // dispatch(setClientOptions({ signUpStep: "location" }));
                    //       console.log("Form submitted successfully from button");
                    //     }
                    //     else{
                    //       console.log("Form fail from button");
                    //     }
                    //     // Take additional actions on successful submission
                    //   })
                    //   .catch((error) => {
                    //     console.log("Form submission failed:", error);
                    //     // Handle submission failure
                    //   });
                    // });
                  
                  }}
                variant="contained" 
                color="primary" 
                style={{ backgroundColor: '#007f7e' }}>
                  {t('chooseLocation')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});
