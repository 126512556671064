import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { forwardRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getMyPatientData } from '../../../../store/hooks/getMyPatientData';
import { PAYMENT_INTERVAL } from '../../../../shared/constants/model.constants';
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Checkbox } from '../../../../components/form/Checkbox';
import { Month } from '../../../../components/form/Month';
import { Year } from '../../../../components/form/Year';
import { Select } from '../../../../components/form/Select';
import { States } from '../../../../components/form/States';
import { signupUpsertPaymentProfile } from '../../../../store/actions/signupActions';
import { setClientOptions } from '../../../../store/actions/clientOptionsActions';
import { validateAndSubmitForm } from '../../../../shared/utils/gen-utils';
import { useTranslation } from 'react-i18next';

const creditCardSchema = Yup.string().test('is-valid-credit-card', 'Invalid credit card number', (value) => {
  // Remove any non-digit characters
  const sanitizedValue = value?.replace(/\D/g, '');
  if (!sanitizedValue) {
    return false;
  }
  // Perform Luhn algorithm check
  let sum = 0;
  let shouldDouble = false;
  for (let i = sanitizedValue.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitizedValue.charAt(i), 10);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
});
const validationSchema = Yup.object({
  useCCOnFile: Yup.boolean(),
  paymentInterval: Yup.number().when('useCCOnFile', {
    is: false,
    then: (schema) => schema.required('Payment interval is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  cardNumber: Yup.string().when('useCCOnFile', {
    is: false,
    then: (schema) => creditCardSchema.required('Card number is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  expirationMonth: Yup.string().when('useCCOnFile', {
    is: false,
    then: (schema) =>
      schema.required('Expiration month is required').test(
        'valid-expiration-date',
        'Expiration date must be the current month or later',
        function (value) {
          const { expirationYear } = this.parent; // Get the expirationYear from the same form
          
          // Only validate if both expirationMonth and expirationYear are present
          if (!value || !expirationYear) {
            return true; // Skip validation if any field is missing
          }

          // Get the current month and year
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1; // JS months are 0-indexed, so +1
          const currentYear = currentDate.getFullYear();

          // Convert the expirationMonth and expirationYear to numbers
          const expMonth = parseInt(value, 10);
          const expYear = parseInt(expirationYear, 10);

          // Compare the expiration date with the current date
          if (expYear > currentYear || (expYear === currentYear && expMonth >= currentMonth)) {
            return true; // Valid if it's the current month or later
          }

          return false; // Invalid if it's a past date
        }
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  expirationYear: Yup.string().when('useCCOnFile', {
    is: false,
    then: (schema) =>
      schema.required('Expiration year is required').test(
        'valid-expiration-year',
        'Expiration date must be the current month or later',
        function (value) {
          const { expirationMonth } = this.parent;

          // Only validate if both expirationMonth and expirationYear are present
          if (!value || !expirationMonth) {
            return true;
          }

          // The expiration month test already handles the logic
          return true; // No need to duplicate validation here, as it's part of the combination
        }
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  cvv: Yup.string().when('useCCOnFile', {
    is: false,
    then: (schema) => schema
      .required('CVV is required')
      .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits'),
    otherwise: (schema) => schema.notRequired(),
  }),
  preferredChargeDay: Yup.number().when('useCCOnFile', {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema
      .required('Preferred Charge Day is required')
      .min(1, 'Preferred Charge Day must be greater than or equal to 1')
      .max(10, 'Preferred Charge Day must be less than or equal to 10'),
  }),
  sameAddressAsPersonal: Yup.boolean(),
  
  // Conditional address fields depending on useCCOnFile and sameAddressAsPersonal
  streetAddress1: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('Street address is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  streetAddress2: Yup.string(),
  firstName: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('First name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  lastName: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('Last name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  city: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('City is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  state: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  code: Yup.string().when(['useCCOnFile', 'sameAddressAsPersonal'], {
    is: (useCCOnFile: any, sameAddressAsPersonal: any) => !useCCOnFile && !sameAddressAsPersonal,
    then: (schema) => schema.required('ZIP is required').matches(/^[0-9]+$/, 'Must be only digits'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export type SignupRegisterPaymentInfoType = Yup.InferType<typeof validationSchema>;

export type SignupRegisterPaymentInfoProps = { onSuccess: () => void };

export const SignupRegisterPaymentInfo = forwardRef<FormikProps<SignupRegisterPaymentInfoType>, SignupRegisterPaymentInfoProps>(({ onSuccess }, ref) => {
  const myPatientData = getMyPatientData();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const hasExistingProfile = useMemo(() => !!myPatientData?.payment_data?.anetccProfileId, [myPatientData?.payment_data?.anetccProfileId]);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        useCCOnFile: hasExistingProfile,
        paymentInterval: myPatientData?.patient_plan?.paymentIntervalId as PAYMENT_INTERVAL,
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvv: '',
        preferredChargeDay: 0,
        sameAddressAsPersonal: false,
        firstName: '',
        lastName: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '0',
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        return new Promise((resolve) => {
          dispatch(
            signupUpsertPaymentProfile(
              {
                ...values,
                ...(values.useCCOnFile ? { expirationMonth: 1, expirationYear: 1, preferredChargeDay: 1, state: 1 } : {}),
              },
              () => {
                onSuccess?.();
                resolve({
                  status: 'success',
                })
              },
              () => {},
              '', //Payment info updated
              true,
              true,
            ),
          );
        });

      }}
      enableReinitialize={true}
    >
      {({ setFieldValue, values, touched, errors, submitForm, isValid, validateForm, setTouched }) => {
        return (
          <Form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap', // This will allow the items to wrap on smaller screens
                marginBottom: '0px',
                gap: 2, // This creates a gap between items
                mt: 2,
              }}
            >
              <Card
                elevation={1}
                sx={{
                  ...(values.paymentInterval === PAYMENT_INTERVAL.MONTHLY && {
                    backgroundColor: '#7b287c',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  }),
                }}
                onClick={() => {
                  setFieldValue('paymentInterval', PAYMENT_INTERVAL.MONTHLY);
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center" sx={{ width: '100%', cursor: 'pointer' }}>
                    {t('monthlyByCreditDebitCard')}
                  </Typography>
                </CardContent>
              </Card>

              <Card
                elevation={1}
                sx={{
                  ...(values.paymentInterval === PAYMENT_INTERVAL.ANNUAL && {
                    backgroundColor: '#7b287c',
                    '& .MuiTypography-root': {
                      color: '#ffffff',
                    },
                  }),
                }}
                onClick={() => {
                  setFieldValue('paymentInterval', PAYMENT_INTERVAL.ANNUAL);
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center" sx={{ width: '100%', cursor: 'pointer' }}>
                    {t('annualByCreditDebitCard')}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <ErrorMessage name={`paymentInterval`}>{(msg) => <Typography color="error">{msg}</Typography>}</ErrorMessage>

            {hasExistingProfile && (
              <Grid item xs={12}>
                <Field as={Checkbox} name="useCCOnFile" label={t("Use Existing CC Profile")} variant="outlined" />
              </Grid>
            )}

            <Grid container spacing={2} sx={{ marginTop: '12px' }}>
              {/* Card Number */}

              {(!values.useCCOnFile) && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="cardNumber"
                      label={t('cardNumber')}
                      variant="outlined"
                      error={touched.cardNumber && Boolean(errors.cardNumber)}
                      helperText={<ErrorMessage name="cardNumber" />}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Month}
                      name="expirationMonth"
                      label={t('expirationMonth')}
                      variant="outlined"
                      error={touched.expirationMonth && Boolean(errors.expirationMonth)}
                      helperText={<ErrorMessage name="expirationMonth" />}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Year}
                      name="expirationYear"
                      label={t('expirationYear')}
                      variant="outlined"
                      error={touched.expirationYear && Boolean(errors.expirationYear)}
                      helperText={<ErrorMessage name="expirationYear" />}
                      fullWidth
                    />
                  </Grid>

                  {/* CVV Code */}
                  <Grid item sm={3} xs={12}>
                    <Field
                      as={TextField}
                      name="cvv"
                      label={t('cvvCode')}
                      variant="outlined"
                      error={touched.cvv && Boolean(errors.cvv)}
                      helperText={<ErrorMessage name="cvv" />}
                      fullWidth
                    />
                  </Grid>

                  {/* Preferred Charge Date */}
                  <Grid item xs={12} sm={3}>
                    <Field
                      as={Select}
                      name="preferredChargeDay"
                      label={t('preferredChargeDate')}
                      variant="outlined"
                      error={touched.preferredChargeDay && Boolean(errors.preferredChargeDay)}
                      helperText={<ErrorMessage name="preferredChargeDay" />}
                      options={Array.from({ length: 10 }, (_, i) => i + 1).map((i) => ({ label: i, value: i }))}
                      fullWidth
                    />
                  </Grid>

                  {/* Same Address Checkbox */}
                  <Grid item xs={12}>
                    <Field as={Checkbox} name="sameAddressAsPersonal" label={t("Same address as personal information?")} variant="outlined" />
                  </Grid>

                  {/* Address Fields */}
                  {!values.sameAddressAsPersonal && (
                    <>
                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="firstName"
                          label={t("firstName")}
                          variant="outlined"
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={<ErrorMessage name="firstName" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="lastName"
                          label={t("lastName")}
                          variant="outlined"
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={<ErrorMessage name="lastName" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="streetAddress1"
                          label={t('addressLine1')}
                          variant="outlined"
                          error={touched.streetAddress1 && Boolean(errors.streetAddress1)}
                          helperText={<ErrorMessage name="streetAddress1" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Field
                          as={TextField}
                          name="streetAddress2"
                          label={t('addressLine2')}
                          variant="outlined"
                          error={touched.streetAddress2 && Boolean(errors.streetAddress2)}
                          helperText={<ErrorMessage name="streetAddress2" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={4} xs={6}>
                        <Field
                          as={TextField}
                          name="city"
                          label={t('city')}
                          variant="outlined"
                          error={touched.city && Boolean(errors.city)}
                          helperText={<ErrorMessage name="city" />}
                          fullWidth
                        />
                      </Grid>

                      <Grid item sm={4} xs={6}>
                        <Field as={States} name={'state'} label={'State'} error={touched.state && Boolean(errors.state)} helperText={<ErrorMessage name="state" />} />
                      </Grid>

                      {/* Zip Code */}
                      <Grid item xs={12} sm={4}>
                        <Field
                          as={TextField}
                          name="code"
                          label={t('zipCode')}
                          fullWidth
                          variant="outlined"
                          error={touched.code && Boolean(errors.code)}
                          helperText={<ErrorMessage name="code" />}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <Button 
                  onClick={() => {
                    validateAndSubmitForm(
                      validateForm,
                      setTouched,
                      submitForm,
                      isValid,
                      () => {
                        dispatch(setClientOptions({ signUpStep: 'review' }));
                      },
                      (error: any) => {
                        console.log("values", values)
                        if (values.useCCOnFile){
                          dispatch(setClientOptions({ signUpStep: 'review' }));
                        }
                        else{
                          console.log("Form submission failed or has errors", error);
                        }
                      }
                    );
                  }}
                  variant="contained" color="primary" style={{ backgroundColor: '#007f7e' }}>
                  {t('review')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});
