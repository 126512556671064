import { AppBar, Toolbar } from "@mui/material";
import LanguageMenu from "../shared/LanguageMenu";
import { useSelector } from "../../store";
import { IRootState } from "../../interfaces/store";

export const Header = () => {
  const email = useSelector((state: IRootState) => state.main.my_user?.email);
  const mainState = useSelector((state: IRootState) => state.main);

  return (
    <AppBar
      key={email}
      style={{ backgroundColor: "white" }}
      position="fixed"
      sx={{
        width: { sm: "100%" },
      }}
    >
      <Toolbar>
        {/* Central Div with maxWidth */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: 1200,
              width: "100%",
            }}
          >
            {/* Logo Section */}
            <div>
              <img
                onClick={() => {
                  console.log(mainState)
                }}
                alt={"logo"}
                width={200}
                style={{ cursor: "pointer" }}
                src={process.env.PUBLIC_URL + "/comfort-logo.png"}
              />
            </div>

            {/* Text Section, centered content if needed */}
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {/* Center any additional content here */}
            </div>
            {/* Avatar Menu Section */}
              <div
                style={{
                  cursor: "pointer",
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{color: 'black'}}>{email || ''}</div>
                <LanguageMenu />
              </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
