import { ElementRef, forwardRef, useEffect, useMemo } from 'react';
import { getLocationsArray } from '../../store/selectors/main';
import { useSelector } from '../../store';
import { Select, SelectItem, SelectProps } from './Select';
import { useDispatch } from 'react-redux';
import { getAllLocations } from '../../store/actions/signupActions';

export type LocationProps = Omit<SelectProps, 'options'>;

export const Location = forwardRef<ElementRef<typeof Select>, LocationProps>((props, ref) => {
  const dispatch = useDispatch();
  const locations = useSelector(getLocationsArray);

  const options: SelectItem[] = useMemo(() => locations?.map((i) => ({ value: i.id?.toString(), label: i.name })) ?? [], [locations]);

  useEffect(() => {
    dispatch(getAllLocations({}));
  }, []);

  return <Select ref={ref} {...props} options={options} />;
});
