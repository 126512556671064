import { FormControl, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { ElementRef, forwardRef } from 'react';

export type CheckboxProps = MuiCheckboxProps & {
  label?: string;
  error?: boolean;
  helperText?: string;
  value?: boolean;
};

export const Checkbox = forwardRef<ElementRef<typeof MuiCheckbox>, CheckboxProps>(({ label, error, helperText, value, ...props }, ref) => {
  return (
    <FormControl fullWidth error={error}>
      <FormControlLabel control={<MuiCheckbox ref={ref} {...props} checked={value} />} label={label} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
