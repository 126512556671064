import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { APP_DESCRIPTION_P1, APP_DESCRIPTION_P2 } from '../../../shared/constants/app-constants';

const Complete = () => {
    const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Full viewport height
      }}
    >
        Complete

    </Box>
  );
};

export default Complete;