import React, { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useDispatch } from 'react-redux';
import { logout, updateMyUser } from '../../store/actions/userActions';
import { AccountCircle } from "@mui/icons-material";
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import { deleteCookie } from '../../shared/utils/gen-utils';
import { doFullLogout, DOT_NET_COOKIE_NAME } from '../../shared/constants/api-constants';
import { getMyPatientData } from '../../store/hooks/getMyPatientData';
import { mergeStateKey, updateMainStateKey } from '../../store/actions/genericAction';

const LanguageMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const myPatientData = getMyPatientData();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (lang: string) => {
    setAnchorEl(null);
    if (myPatientData?.my_user?.email) {
      dispatch(updateMyUser({
        language: lang
      }))
    }
    else{
      dispatch(mergeStateKey({key: 'my_user', value: {language: lang}}))
    }
  }

  return (
    <div>
      <IconButton
        aria-label="language"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {myPatientData?.my_user?.email ? <AccountCircle /> : <LanguageIcon />}
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { handleSelect('en') }}>
          English
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('es') }}>
          Spanish
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('vi') }}>
          Vietnamese
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('de') }}>
          German
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('it') }}>
          Italian
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('pt') }}>
          Portuguese
        </MenuItem>
        <MenuItem onClick={() => { handleSelect('ja') }}>
          Japanese
        </MenuItem>
        <Divider />
        {myPatientData?.my_user?.email && 
        <MenuItem onClick={() => {

          dispatch(logout({}, () => {
            doFullLogout()
          }, () => {console.log("logouit fail")}, "Logout Success", true, true))

        }}>
         
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>}
      </Menu>
    </div>
  );
};

export default LanguageMenu;