import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "./interfaces/store";
import "./App.css";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import Loading from "./components/general/Loading";
import { getMyUserData } from "./store/actions/userActions";
import { Box, CssBaseline } from "@mui/material";
import { Header } from "./components/layout/Header";
import { Route, Routes } from "react-router-dom";
import Signup from "./pages/private/signup/Signup";
import { SignupRegister } from "./pages/private/signup/register/SignupRegister";
import Complete from "./pages/public/signnow/Complete";
import { SignupThankYouPage } from "./pages/private/signup/register/SignupRegisterThankYou";
import SignupLanding from "./pages/private/signup/SignupLanding";
import { getCookie } from "./shared/utils/gen-utils";
import { DOT_NET_COOKIE_NAME } from "./shared/constants/api-constants";
import InactivityModal from "./components/general/InacvityModal";

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const error = useSelector((state: IRootState) => state.main.error);
  const success = useSelector((state: IRootState) => state.main.success);
  const loading = useSelector((state: IRootState) => state.main.loading);
  const mainKey = useSelector((state: IRootState) => state.main.mainKey);
  const dispatch = useDispatch();

  useEffect(() => {
    const pathName = window.location.pathname
      if (pathName !== 'signup' && pathName !== '/signup') {
        dispatch(getMyUserData());
      } else {
        console.log("The path is not /signup");
      }

  }, []);  // Run once on mount

  useEffect(() => {
    if (error.error) {
      enqueueSnackbar(error.error, { variant: "error" });
    }
  }, [error]);

  useEffect(() => {
    if (success.message) {
      enqueueSnackbar(success.message, { variant: "success" });
    }
  }, [success]);

  const theme = useSelector((state: IRootState) => state.main.theme);

  return (
    <ErrorBoundary>

      <CssBaseline />
      <Box key={mainKey}
        sx={{
          display: "flex",
          flexDirection: "column", // Stack children vertically
          width: "100vw", // Full viewport width
          minHeight: "100vh", // Full viewport height
          backgroundColor: theme.colors.background,
          backgroundImage: `url(${process.env.PUBLIC_URL}/bg-teeth.png)`,
          color: theme.colors.font,
        }}
      >
        <Header/>
        <Box
          component="main"
          sx={{
            flex: 1, // Take up remaining height
            display: "flex",
            flexDirection: "column", // Stack its children
            width: "100%", // Full width of the parent
            marginTop: "64px",
          }}
        >
          <Routes>
            <Route path="/Complete" element={<Complete />} />
            <Route path="/" element={<SignupLanding />} />
            <Route path="Signup" element={<Signup />} />
            <Route path="Signup/Register" element={<SignupRegister />} />
            <Route path="Signup/Thankyou" element={<SignupThankYouPage />} />
          </Routes>
        </Box>
      </Box>
      <Loading loading={loading} />
    </ErrorBoundary>
  );
}

App.propTypes = {
  /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
            // email ? <AppAuthenticated /> : <AppPublic />
     * 
     */
  window: PropTypes.func,
};

export default App;
