import { groupBy, values } from "lodash";
import { Form, Formik, FormikProps } from "formik";
import { forwardRef, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "../../../../store";
import {
  getAddressesArray,
  getLocationsArray,
  getStatesArray,
} from "../../../../store/selectors/main";
import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import {
  getAllLocations,
  setLocation,
} from "../../../../store/actions/signupActions";
import { getMyPatientData } from "../../../../store/hooks/getMyPatientData";
import { setClientOptions } from "../../../../store/actions/clientOptionsActions";
import { showErrorMessage } from "../../../../store/actions/genericAction";
import { useTranslation } from "react-i18next";
import { validateAndSubmitForm } from "../../../../shared/utils/gen-utils";

const validationSchema = Yup.object().shape({
  id: Yup.number().min(1, "Location is required"),
});

export type SignupRegisterLocationFormType = Yup.InferType<
  typeof validationSchema
>;

export type SignupRegisterLocationProps = { onSuccess: () => void };

export const SignupRegisterLocation = forwardRef<
  FormikProps<SignupRegisterLocationFormType>,
  SignupRegisterLocationProps
>(({ onSuccess }, ref) => {

  const {t} = useTranslation();
  const myPatientData = getMyPatientData();
  const addresses = useSelector(getAddressesArray);
  const locationsAll = useSelector(getLocationsArray);
  const locations = locationsAll?.filter((location) => location.active && location.name != "HQ");
  const states = useSelector(getStatesArray);
  const dispatch = useDispatch();

  const [showAllLocations, setShowAllLocations] = useState(false);

  const groupedLocations = useMemo(() => {
    const enrichedAddress = addresses?.map((address) => ({
      ...address,
      state: states?.find((i) => i.id === address?.stateId),
    }));
    const enrichedLocations = locations?.map((location) => ({
      ...location,
      address: enrichedAddress?.find((i) => i.id === location.addressId),
    }));
    const groupedLocations = groupBy(
      enrichedLocations,
      (location) => location.address?.state?.abbreviation
    );
    let patientState = null;
    if (!showAllLocations && myPatientData?.address?.stateId) {
      patientState = states.find((x) => x.id === myPatientData!.address!.stateId);
    }
    else{
      console.log("couldn't find the id from address", myPatientData);
    }

    if (patientState != null) {
      const locationsOfFilteredState = groupedLocations[patientState!.abbreviation!]
      if (locationsOfFilteredState) {
        const groupedFilteredLocations = {
          [patientState!.abbreviation!]: locationsOfFilteredState
        }
        return groupedFilteredLocations
      }
    }

    return groupedLocations;


  }, [locations, addresses, states, showAllLocations]);

  useEffect(() => {
    dispatch(getAllLocations({}));
  }, [dispatch]);

  // Create a ref for the submit button
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        id: myPatientData?.patient?.locationId,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        return new Promise((resolve, reject) => {
          if (values?.id === 1) {
            dispatch(showErrorMessage("Please select a location"));
            return;
          }
          actions.setSubmitting(false);
          dispatch(
            setLocation(
              { id: values.id }, () => {
                resolve({
                  status: "success",
                });
              }
            )
          );
        })

      }}
    >
      {({ values, errors, setValues, submitForm, validateForm, setTouched, isValid }) => {
        if (errors.id) window.scroll(0, 0);

        // Function to handle card click
        const handleCardClick = (locationId: any) => {
          setValues({ id: locationId ?? 0 });

          // Scroll the submit button into view after selecting a location
          if (submitButtonRef.current) {
            submitButtonRef.current.scrollIntoView({ behavior: "smooth" });
          }
        };

        return (
          <Form style={{ margin: 16 }}>
            {errors.id && (
              <Alert severity="error" style={{ marginBottom: "2rem" }}>
                Location is required.
              </Alert>
            )}
            <input type="hidden" value="" name="id" />
            <FormControlLabel
              control={
                <Switch
                  checked={showAllLocations}
                  onChange={() => setShowAllLocations(!showAllLocations)}
                  color="primary"
                />
              }
              label={t('showAllLocations')}
            />
            <Grid container spacing={2}>
              {Object.entries(groupedLocations ?? {}).map(
                ([stateAbbreviation, locationsItem]) => (
                  <Fragment key={stateAbbreviation}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        align="left"
                        sx={{ fontSize: "0.85rem" }}
                      >
                        State: {stateAbbreviation}
                        <hr />
                      </Typography>
                    </Grid>
                    {locationsItem.map((location) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2.4}
                        key={location.id}
                      >
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#fafafa",
                            "&:hover": {
                              backgroundColor: "#7b287c",
                              cursor: "pointer",
                              "& .MuiTypography-root": {
                                color: "#ffffff", // All text turns white
                              },
                            },
                            ...(location.id === values.id && {
                              backgroundColor: "#7b287c",
                              "& .MuiTypography-root": {
                                color: "#ffffff",
                              },
                            }),
                          }}
                          onClick={() => handleCardClick(location.id)}
                        >
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              variant="h6"
                              component="div"
                              align="left"
                              gutterBottom
                              sx={{ fontSize: "0.9rem" }}
                            >
                              {location.name}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              align="left"
                              gutterBottom
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {location.address?.street},{" "}
                              {location.address?.city}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              align="left"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {location.phone}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Fragment>
                )
              )}
              <Grid item xs={12}>
                <Button
                  onClick={() => {

                    validateAndSubmitForm(
                      validateForm,
                      setTouched,
                      submitForm,
                      isValid,
                      () => {
                        if (values?.id === 1) {
                          dispatch(showErrorMessage("Please select a location"));
                          console.log("values", values)
                        }
                        else{
                          dispatch(setClientOptions({ signUpStep: "planType" }));
                        }
                        //
                      },
                      (error) => {
                        console.log("Form submission failed or has errors", error);
                        dispatch(showErrorMessage("Please select a location"));
                        // Handle additional failure logic
                      }
                    );

                  // submitForm()
                  //   .then(() => {

                      
                  //     dispatch(setClientOptions({ signUpStep: "planType" }));
                  //     console.log("Form submitted successfully from button");
                  //     // Take additional actions on successful submission
                  //   })
                  //   .catch((error) => {
                  //     console.log("Form submission failed:", error);
                  //     // Handle submission failure
                  //   });
                }}
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#007f7e" }}
                  ref={submitButtonRef} // Attach the ref to the submit button
                >
                  {t('choosePlanType')}
                </Button>
              </Grid>
            </Grid>
          </Form>
          
        );
      }}
    </Formik>
  );
});
