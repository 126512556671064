import { forwardRef } from "react";
import * as Yup from "yup";
import { Box, Button, Grid } from "@mui/material";
import { Checkbox } from "../../../../components/form/Checkbox";
import { Field, Form, Formik, FormikProps } from "formik";
import { ChevronRight } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { useSelector } from "../../../../store";
import { getMyPatientData } from "../../../../store/hooks/getMyPatientData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setClientOptions } from "../../../../store/actions/clientOptionsActions";
import { validateAndSubmitForm } from "../../../../shared/utils/gen-utils";
import { showErrorMessage } from "../../../../store/actions/genericAction";

const validationSchema = Yup.object().shape({
  terms: Yup.boolean().oneOf(
    [true],
    "You must accept the terms of service to continue."
  ),
});
export type SignupRegisterOverviewFormType = Yup.InferType<
  typeof validationSchema
>;

export type SignupRegisterOverviewProps = { onSuccess: () => void };


const sectionStyle = {
  marginBottom: '20px',
};

const headingStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '10px',
  borderBottom: '1px solid #000',
};

const itemStyle = {
  fontSize: '16px',
  marginBottom: '5px',
};

const subheadingStyle = {
  fontWeight: 'bold',
  marginBottom: '5px',
};

const linkStyle = {
  display: 'block',
  color: '#007bff',
  textDecoration: 'none',
  marginBottom: '10px',
};


export const SignupRegisterOverview = forwardRef<
  FormikProps<SignupRegisterOverviewFormType>,
  SignupRegisterOverviewProps
>(({ onSuccess }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myPatientData = getMyPatientData();
  if (!myPatientData?.my_user?.email) {
    navigate("/signup");
  }
  return (
    <Formik
      innerRef={ref}
      initialValues={{
        terms: true,
      }}
      validationSchema={validationSchema}
      onSubmit={(_, actions) => {
        return new Promise((resolve, reject) => {
          onSuccess();
          actions.setSubmitting(false);
          return resolve({
            status: 'success'
          })
        });


      }}
    >
      {({ handleChange, handleBlur, values, touched, errors, submitForm, isValid, validateForm, setTouched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item lg={8} xs={12}>
              <Box
                sx={{
                  bgcolor: "white",
                  p: 2,
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                  textAlign: "left",
                }}
              >
                <h3>{t(`Information about the Gold Plan`)}</h3>
                <hr />
                <p>
                  Comfort Dental <b>Gold Plan</b>{" "}
                  {t(
                    `is a reduced-fee dental membership plan that allows individuals and families to receive quality dental services from Comfort Dental dentists at reduced prices. The`
                  )}{" "}
                  <b>Gold Plan</b>{" "}
                  {t(
                    `offers the access of a network, plus the individualized attention of private care. You, your spouse, dependent children up to 26 years of age, and children over 26 with a developmental disability or physical handicap are eligible. Individuals under the age of 18 may only be members as the dependents of adults.`
                  )}
                </p>

                <p>
                  {t(
                    `Members must choose a provider/office from the list of OFFICE LOCATIONS provided in this form. Provider information is also available at `
                  )}
                  <a className="comfortLink" href="www.comfortdental.com">
                    www.comfortdental.com
                  </a>{" "}
                  {t(
                    `or by calling the Gold Plan office. To receive care, simply call your selected office for an appointment after your effective date. All Comfort Dental Offices offer `
                  )}
                  <b>{t(`EVENING AND SATURDAY`)}</b> {t(`appointments.`)}
                </p>

                <p>
                  {t(`The Gold Plan is a yearly membership with the option to pay membership fees monthly or annually. Members who enroll prior to the 10th of the month will have their membership begin on the first day of the following month. Those enrolling after the 10th will have their membership begin on the first day of the second month.`)}
                </p>

                <p>
                  {t(`This discount program is NOT a health insurance policy and does not make payments directly to dental service providers. Members are obligated to pay for all dental services, but may receive discounts on dental services from participating providers and the discount range will vary depending on provider type and dental services received. The program does not meet the minimum creditable coverage requirements under any law and is not a Qualified Health Plan under the Affordable Care Act. If you cancel within the first 30 days after activation you will receive a full refund, except for the $12 processing/enrollment fee where permitted by law.`)}
                </p>

                <h3>{t(`Administration`)}</h3>
                <hr />

                <p>{t(`Comfort Dental’s Gold Plan program is administered by`)}:</p>

                <div><b>Comfort Dental Gold Plan, LLC</b></div>
                <div>2540 Kipling St., Lakewood, CO 80215</div>
                <div>(303) 232-2300</div>
                <div>855-526-6524</div>
                <div>goldplan@comfortdental.biz</div>

                <p>{t(`Members who cancel after receiving benefits may be liable for the difference between the Gold Plan fee and the provider’s normal and customary fee for treatment, payable to the provider.`)}</p>
                <p>{t(`Members may change providers or add additional family members by providing a written request and paying any additional membership fees. Changes will be effective 30-days from the receipt of written requests.`)}</p>

                <h3>{t(`Feedback`)}</h3>
                <hr />

                <p>{t(`Complaints should be addressed to`)}:</p>

                <div><b>Comfort Dental Gold Plan Complaints</b></div>
                <div>2540 Kipling St., Lakewood, CO 80215</div>
                <div>Patient Relations Email - asiudyla@comfortdental.biz</div>

                <p>{t(`Complaints will be addressed within 30-days of receipt. If a member remains dissatisfied he or she may contact his or her state’s insurance department. The`)}
                  <b> Gold Plan </b>{t(`does not guarantee the quality of the services or products offered by individual providers.`)}
                </p>

                <h3>{t(`Gold Plan Inclusions`)}</h3>
                <hr />

                <p>{t(`Gold Plan Membership provides you with local anesthetics, examinations, x-rays, and other preventive services at NO CHARGE ($5.00 for Arizona members) plus reduced fees on most other dental services. The Reduced Fee Schedule is included and can also be viewed at`)}
                  &nbsp;
                  <a href="www.comfortdental.com/patient-center/gold-plan/">www.comfortdental.com/patient-center/gold-plan/</a>&nbsp;
                  {t(`for full details on discounted services.`)}</p>

                <p>{t(`Members are charged an office visit fee of $30.00 for each appointment. Orthodontics (BRACES) are provided at $500 discount on the total treatment cost, and payments of just $99/month with NO DOWN PAYMENTS. The reduced fees are paid directly by you to your selected provider. A complete list of dentists and offices is available at`)}
                  <a href="www.comfortdental.com">www.comfortdental.com</a>.
                </p>

                <p>{t(`Members may receive emergency dental care for the relief of pain, bleeding or swelling from any Comfort Dental dentist at any Comfort Dental office when their selected provider is unavailable.`)}</p>

                <p>{t(`This contract is not protected by any state guaranty fund. The program and program administrator is not liable for providing or guaranteeing health services or for the quality of health services rendered. Membership and activation fees apply. The Gold Plan is governed by this Membership Agreement. Participating providers are not available in all areas and are subject to change without notice. Program is not available in all states.`)}</p>

                <p>{t(`I hereby authorize this provider and its employees and assignees to contact me via email and text messaging and to my cellular devices.`)}</p>

                <p>{t(`I understand the benefits, limitations, exclusions and requirements of the Plan and I agree to the following:`)}
                  &nbsp;
                  <b>{t(`I will remain in the plan and pay membership fees for 12 months.`)}</b>
                </p>
                <p>{t(`Where permitted by law, payment of less then 12 months’ membership fees may result in my being charged usual and customary fees for all services (including those already provided) and my being charged remaining months’ fees in a lump sum. Fees for dental services are due to the dentist as services are rendered. Fees for prosthodontic and case restoration services are due to the dentist at the preparation/impression visit. Failure to comply may result in my being charged usual and customary fees for such services. I agree to pay any and all costs in collecting all charges, including but not limited to attorney fees and court costs. Membership must be continuous. Missing monthly payments must be made up for interrupted membership. Processing fees are not refundable.`)}</p>

                <Field
                  as={Checkbox}
                  name="terms"
                  label="I accept these Terms of Service"
                  variant="outlined"
                  value={values.terms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.terms && Boolean(errors.terms)}
                  helperText={touched.terms && errors.terms ? errors.terms : ""}
                />
                <Button
                  onClick={() => {

                    validateAndSubmitForm(
                      validateForm,
                      setTouched,
                      submitForm,
                      isValid,
                      () => {
                        dispatch(setClientOptions({ signUpStep: "yourInfo" }));
                      },
                      (error: any) => {
                        console.log("Form submission failed or has errors", error);
                        // Handle additional failure logic
                      }
                    );


                }}
                  endIcon={<ChevronRight />}
                  variant={"outlined"}
                  type="submit"
                  style={{ padding: 10, marginTop: 20 }}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Box
                sx={{
                  bgcolor: "grey.200",
                  p: 2,
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <div>
                  <div style={sectionStyle}>
                    <div style={headingStyle}>{t('Monthly Membership Fees')}*</div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Individual Member')}</div>
                      {t('$12.50 (1st month charge is $25.00 which includes 1st & last month)')}
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and One Dependent')}</div>
                      {t('$19.50 (1st month charge is $39.00 which includes 1st & last month)')}
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and up to Three Dependents')}</div>
                      {t('$26.50 (1st month charge is $53.00 which includes 1st & last month)')}
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and Four or More Dependents')}</div>
                      {t('$31.50 (1st month charge is $63.00 which includes 1st & last month)')}
                    </div>
                  </div>

                  <div style={sectionStyle}>
                    <div style={headingStyle}>{t('Annual Membership Fees')}*</div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Individual Member')}</div>
                      $150.00
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and One Dependent')}</div>
                      $234.00
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and up to Three Dependents')}</div>
                      $318.00
                    </div>
                    <div style={itemStyle}>
                      <div style={subheadingStyle}>{t('Member and Four or More Dependents')}</div>
                      $378.00
                    </div>
                  </div>

                  <div style={sectionStyle}>
                    <div style={headingStyle}>{t('Limitations and Exclusions')}</div>
                    <a href="#" style={linkStyle}>
                      📄 {t('Dental')}
                    </a>
                    <a href="#" style={linkStyle}>
                      📄 {t('Orthodontic')}
                    </a>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});
